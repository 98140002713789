/* eslint-disable react/jsx-no-target-blank */

import "./styles.scss"

import React, { useState } from "react"

const graph_header = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII"]

const Calculator = ({ interestRateTab, setInterestRateTab }) => {
  const [investment_amount, setInvestmentAmount] = useState(5000)
  const percentage_amount = interestRateTab === "variable" ? 0.1061 : 0.1

  const profit =
    investment_amount >= 5000
      ? (
          investment_amount * (1 + percentage_amount) -
          investment_amount
        ).toFixed(0)
      : null
  const quarterly_payment = (3 * (profit / 12)).toFixed(0)
  const total_profit = (quarterly_payment * 8).toFixed(0)

  const formatted_profit = total_profit
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  const formatted_quarterly_payment = quarterly_payment
    ?.toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  const show_amounts = quarterly_payment !== "0" && profit !== "0"

  return (
    <>
      <div className="investment-content__chart-tabs">
        <button
          className={interestRateTab === "variable" ? "current" : ""}
          onClick={() => setInterestRateTab("variable")}
        >
          Oprocentowanie zmienne
        </button>
        <button
          className={interestRateTab === "pernament" ? "current" : ""}
          onClick={() => setInterestRateTab("pernament")}
        >
          Oprocentowanie stałe
        </button>
      </div>
      <div className="investment-content__calculator">
        <div className="investment-content__calculator__header">
          <div className="investment-content__calculator__header__col">
            <h4>
              Podaj kwotę inwestycji
              <small>(Minimalna kwota inwestycji to 5000zł)</small>
            </h4>
            <div className="investment-content__calculator__header__col__value">
              <input
                min={5000}
                type="number"
                value={investment_amount}
                onChange={e => setInvestmentAmount(e.target.value)}
                onBlur={() =>
                  setInvestmentAmount(
                    Math.round(investment_amount / 5000) * 5000
                  )
                }
              />
            </div>
          </div>
          <div className="investment-content__calculator__header__col">
            <h4>Estymowany zysk całkowity</h4>
            <div className="investment-content__calculator__header__col__value">
              <span>{!!show_amounts ? `${formatted_profit} zł` : "-"}</span>
            </div>
          </div>
          <div className="investment-content__calculator__header__col">
            <h4>Kwartalna wypłata oprocentowania</h4>
            <div className="investment-content__calculator__header__col__value">
              <span>
                {!!show_amounts ? `${formatted_quarterly_payment} zł` : "-"}
              </span>
            </div>
            {/* <div className="investment-content__calculator__graph__timeline__legend">
              <div className="investment-content__calculator__graph__timeline__legend__col">
                <span>Wpłata inwestora</span>
              </div>
              <div className="investment-content__calculator__graph__timeline__legend__col">
                {interestRateTab === "variable" ? (
                  <>
                    <strong>
                      10,57%<sup>1</sup>
                    </strong>
                    <small>WIBOR 3M + MARŻA 4,75%</small>
                  </>
                ) : (
                  <>
                    <strong>10%</strong>
                  </>
                )}
              </div>
              <div className="investment-content__calculator__graph__timeline__legend__col">
                <span>Spłata inwestora</span>
              </div>
            </div>
            <p>Kwartalna wypłata odsetek</p> */}
          </div>
        </div>
        <div className="investment-content__calculator__graph">
          <div className="investment-content__calculator__graph__wrapper">
            <div className="investment-content__calculator__graph__row investment-content__calculator__graph__row--header">
              <div className="investment-content__calculator__graph__row__col">
                <span>Kwartał</span>
              </div>
              {graph_header?.map((item, index) => (
                <div
                  key={index}
                  className="investment-content__calculator__graph__row__col"
                >
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className="investment-content__calculator__graph__row">
              <div className="investment-content__calculator__graph__row__col">
                <span></span>
              </div>
              {[...Array(8).keys()]?.map(item => (
                <div
                  key={item}
                  className="investment-content__calculator__graph__row__col"
                >
                  <h4>
                    {quarterly_payment !== "0"
                      ? `${formatted_quarterly_payment} zł`
                      : "-"}
                  </h4>
                </div>
              ))}
            </div>
            <div className="investment-content__calculator__graph__timeline">
              <div>
                <span></span>
              </div>
              <div className="investment-content__calculator__graph__timeline__legend">
                <div className="investment-content__calculator__graph__timeline__legend__col">
                  <span>Wpłata inwestora</span>
                </div>
                <div className="investment-content__calculator__graph__timeline__legend__col">
                  {interestRateTab === "variable" ? (
                    <>
                      <strong>
                        10,57%<sup>1</sup>
                      </strong>
                      <small>WIBOR 3M + MARŻA 4,75%</small>
                    </>
                  ) : (
                    <>
                      <strong>10%</strong>
                    </>
                  )}
                </div>
                <div className="investment-content__calculator__graph__timeline__legend__col">
                  <span>Dzień spłaty pożyczki</span>
                </div>
              </div>
              <p>
                Kwartalna wypłata oprocentowania wynikającego z umowy pożyczki
              </p>
            </div>
          </div>
        </div>
        <small>
          * Kalkulator prezentuje zysk brutto, a wynagrodzenie netto wypłacone
          Inwestorowi będzie pomniejszone o 19% podatku od dochodu z tytułu
          odsetek
        </small>
        <small>
          * przy założeniu, że zwrot kwoty zainwestowanej następuje po 24
          miesiącach, (stopa bazowa WIBOR 3M dzień 02.01.2025 r. wynosiła
          5,82%.), Wysokość stopy bazowej WIBOR 3M będzie aktualizowana w każdym
          następnym kwartale i przyjmowana na pierwszy dzień roboczy każdego
          następującego po sobie kwartału (kwartalna aktualizacja oprocentowania
          WIBOR 3M). Estymowany w kalkulatorze zysk nie uwzględnia podatku
          dochodowego.
        </small>
      </div>
    </>
  )
}

export default Calculator
